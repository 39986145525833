.leftSideNav {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-wrap: nowrap;
  height: 100%;
}

hr {
  background-color: #f4f4f4;
}