.logo {
  padding: 1px;
  box-sizing: border-box;
  margin: 30px 15px;
  text-align: center;
}

.logo img {
  max-height: 45px;
  max-width: 100%;
}