body {
  margin: 0;
  padding: 0;
  font-family: "Open Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #e7e7e7d3;
}

h1 {
  font-family: Open Sans;
  font-size: 30px;
  color: #160032;
  font-weight: 700;
  letter-spacing: -1px;
}

h2 {
  font-family: Open Sans;
  font-size: 14px;
  color: #212529;
  font-weight: 600;
}

h3 {
  font-family: Open Sans;
  font-size: 11px;
  color: #fefefe;
  font-weight: 700;
  text-transform: uppercase;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

::selection {
  background-color: #00009b;
  color: white;
}

.form-group input {
  background-color: #f4f4f4;
  border: 1px solid #ddd;
  box-shadow: 1px 1px #747474;
  font-size: 12px;
  color: #767676;
}

input[type="checkbox"] {
  display: none;
}

input[type="checkbox"] + label span.CustomCheckbox {
  display: inline-block;
  position: relative;
  vertical-align: middle;
  line-height: 12px;
  width: 15px;
  height: 15px;
  margin-bottom: 2px;
  margin-right: 3px;
  background: #fff left top no-repeat;
  border: 0.1rem solid #767676;
  border-radius: 20%;
  cursor: pointer;
  color: #fff;
}

input[type="checkbox"]:checked + label span.CustomCheckbox {
  background: #00009b -19px top no-repeat;
  border-color: #00009b;
}

input[type="checkbox"] + span {
  margin-right: 4px;
}

.form-group label {
  color: #767676;
  font-size: 14px;
  font-weight: 600;
}